import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface ScheduleQueryArgs {
	dentist?: string
	start?: string
	end?: string
	search?: string
	page?: number
}

export function useScheduleQuery({
	dentist,
	search,
	start,
	end,
	page,
}: ScheduleQueryArgs) {
	return useQuery({
		queryKey: ['schedule_search', { dentist, start, end, search, page }],
		async queryFn(ctx) {
			const searchParams = new URLSearchParams(
				[
					['start', start],
					['end', end],
					['search', search],
					['dentist', dentist],
					['page', String(page) ?? '1'],
				].filter(([, value]) => {
					return !!value
				})
			)
			console.log({ searchParams: searchParams.toString() })
			const r = await api.get<AppointmentsResponse>(
				`/appointments?${searchParams}`,
				{ signal: ctx.signal }
			)
			return r.data
		},
		placeholderData: (prev) => prev,
	})
}

interface AppointmentsResponse {
	data: {
		customer?: { _id: string; name: string }
		dentist?: { _id: string; name: string }
		start: string
		end: string
		status: string
		_id: string
		notes?: string
	}[]
	meta: {
		total: number
		perPage: number
	}
}
