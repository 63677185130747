import React from 'react'
import {
	Box,
	Center,
	Container,
	Flex,
	Grid,
	IconButton,
	Spinner,
	Tab,
	TabList,
	Tabs,
	Text,
} from '@chakra-ui/react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { getUserInfoInStorage } from 'services/api/auth'
import { ScheduleTableDay } from './components/ScheduleTable/ScheduleTableDay'
import { ScheduleTableWeek } from './components/ScheduleTable/ScheduleTableWeek'
import { ScheduleTableMonth } from './components/ScheduleTable/ScheduleTableMonth'
import { TableMonthControl } from './components/ScheduleTable/TableMonthControl'
import { useProfessionals } from './queries/useProfessionals'
import { ScheduleSettings } from './components/ScheduleSettings'
import { ProfessionalSelect } from './components/ProfessionalSelect'
import { SearchIcon } from '@chakra-ui/icons'
import { Can } from 'ability'

const MemoizedScheduleTableDay = React.memo(ScheduleTableDay)
const MemoizedScheduleTableWeek = React.memo(ScheduleTableWeek)
const MemoizedScheduleTableMonth = React.memo(ScheduleTableMonth)

function parseType(type: string) {
	switch (type) {
		case 'week':
			return 'week'
		case 'month':
			return 'month'
		default:
			return 'day'
	}
}

export default function Schedule() {
	const navigate = useNavigate()
	const { professionalId, type } = useParams()
	const tab = parseType(type)

	const professionals = useProfessionals()

	const handleTabChange = (index: number) => {
		switch (index) {
			case 0:
				sessionStorage.setItem('agenda:type', 'day')
				return navigate(`/agendamentos/${professionalId}/day`, {
					replace: true,
				})
			case 1:
				sessionStorage.setItem('agenda:type', 'week')
				return navigate(`/agendamentos/${professionalId}/week`, {
					replace: true,
				})
			case 2:
				sessionStorage.setItem('agenda:type', 'month')
				return navigate(`/agendamentos/${professionalId}/month`, {
					replace: true,
				})
			default:
				return undefined
		}
	}

	const { isMobile } = useBreakpointsMediaQuery()

	return (
		<ScheduleRedirects>
			<Grid h="100%">
				<Can I="read" a="Appointment" passThrough>
					{(allowed) =>
						allowed ? (
							<Tabs
								index={['day', 'week', 'month'].indexOf(tab)}
								onChange={handleTabChange}
							>
								<Flex
									flexWrap="wrap"
									alignItems={{
										sm: 'flex-end',
										md: 'center',
									}}
									rowGap={4}
									columnGap={{ sm: '16px', md: '20px' }}
									p={6}
								>
									{isMobile ? (
										<Flex
											flexDirection="column"
											rowGap="1.5rem"
											width="100%"
										>
											<TabList defaultValue={tab}>
												<Tab value="day">Dia</Tab>
												<Tab value="week">Semana</Tab>
												<Tab value="month">Mês</Tab>
											</TabList>
											<Flex
												alignItems="flex-end"
												justifyContent="space-between"
											>
												<Container
													p={0}
													m={0}
													width={{
														sm: 224,
														md: 'auto',
													}}
												>
													<ProfessionalSelect
														data={
															professionals.data
														}
													/>
												</Container>

												<IconButton
													width="32px"
													ml={isMobile ? '' : 'auto'}
													mr="2"
													aria-label="Buscar Agendamentos"
													title="Buscar Agendamentos"
													icon={<SearchIcon />}
													onClick={() =>
														navigate(
															`/agendamentos/buscar`
														)
													}
												/>

												<ScheduleSettings />
											</Flex>
											{type === 'month' && (
												<TableMonthControl />
											)}
										</Flex>
									) : (
										<Flex
											alignItems="flex-end"
											columnGap="20px"
											width="100%"
										>
											<Container
												p={0}
												m={0}
												width={{ sm: 224, md: 'auto' }}
											>
												<ProfessionalSelect
													data={professionals.data}
												/>
											</Container>
											<TabList defaultValue={tab}>
												<Tab value="day">Dia</Tab>
												<Tab value="week">Semana</Tab>
												<Tab value="month">Mês</Tab>
											</TabList>
											{type === 'month' && (
												<TableMonthControl />
											)}

											<IconButton
												width="32px"
												ml={isMobile ? '' : 'auto'}
												mr="2"
												aria-label="Buscar Agendamentos"
												title="Buscar Agendamentos"
												icon={<SearchIcon />}
												onClick={() =>
													navigate(
														`/agendamentos/buscar`
													)
												}
											/>
											<ScheduleSettings />
										</Flex>
									)}
								</Flex>

								{/* {professionalId && (
					<Flex px={6} pb={6} justifyContent="center">
						<ScheduleNew />
					</Flex>
				)} */}

								{professionalId ? (
									<Box px={{ md: 6 }} h="full">
										<SchedulePanels />
									</Box>
								) : (
									<Flex
										alignItems="center"
										justifyContent="center"
									>
										Selecione um profissional!
									</Flex>
								)}
							</Tabs>
						) : (
							<Center h="100%">
								<Text>
									Você não tem permissão para acessar esse
									recurso
								</Text>
							</Center>
						)
					}
				</Can>
			</Grid>
		</ScheduleRedirects>
	)
}

function SchedulePanels() {
	const { type } = useParams()

	switch (type) {
		case 'month':
			return <MemoizedScheduleTableMonth />
		case 'week':
			return <MemoizedScheduleTableWeek />
		default:
			return <MemoizedScheduleTableDay />
	}

	// return (
	// 	<TabPanels>
	// 		<TabPanel px={{ sm: 0, md: 6 }} maxW="100vw" overflowX="auto">
	// 			<Outlet />
	// 		</TabPanel>
	// 		<TabPanel px={{ sm: 0, md: 6 }} maxW="100vw" overflowX="auto">
	// 			<Outlet />
	// 		</TabPanel>
	// 		<TabPanel
	// 			px={{ sm: 0, md: 6 }}
	// 			maxW="100vw"
	// 			overflowX="auto"
	// 			display="flex"
	// 			alignItems="center"
	// 			justifyContent="center"
	// 		>
	// 			<Outlet />
	// 		</TabPanel>
	// 	</TabPanels>
	// )
}

function ScheduleRedirects({ children }: { children: JSX.Element }) {
	const { professionalId } = useParams()
	const professionals = useProfessionals()

	if (professionals.isPending || professionals.isLoadingError) {
		return (
			<Center h="100%">
				<Spinner />
			</Center>
		)
	}

	if (professionalId)
		sessionStorage.setItem('agenda:professionalId', professionalId)

	const sessionAgendaProfessionalId =
		sessionStorage.getItem('agenda:professionalId') ?? ''
	const sessionAgendaType = sessionStorage.getItem('agenda:type') ?? ''
	const sessionAgendaDate = sessionStorage.getItem('agenda:date') ?? ''
	const isNotFoundProfessional = () =>
		professionalId &&
		!professionals.data.some((it) => it.id === professionalId)
	if (isNotFoundProfessional()) {
		sessionStorage.removeItem('agenda:professionalId')
		return <Navigate to="/agendamentos" replace />
	}

	const hasPersistedProfessional = () =>
		!professionalId && sessionAgendaProfessionalId
	if (hasPersistedProfessional()) {
		const targetType = sessionAgendaType ?? 'day'
		return (
			<Navigate
				to={`/agendamentos/${sessionAgendaProfessionalId}/${targetType}?date=${sessionAgendaDate}`}
				replace
			/>
		)
	}

	const defaultProfessional = professionalId
		? undefined
		: professionals.data?.length === 1
		? professionals.data.at(0)
		: professionals.data?.find(
				(it) => it.userId === getUserInfoInStorage()?.id
		  )

	if (defaultProfessional) {
		return (
			<Navigate
				to={`/agendamentos/${defaultProfessional.id}/day`}
				replace
			/>
		)
	}

	return children
}
