/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import { Link, useParams, useSearchParams } from 'react-router-dom'
import {
	Flex,
	useMediaQuery,
	Text,
	Stack,
	Grid,
	Popover,
	PopoverTrigger,
	PopoverContent,
	useColorModeValue,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isTodayPlugin from 'dayjs/plugin/isToday'

import { range } from 'app/utils/range'
import {
	useProfessionalSchedule,
	ScheduleEvent,
} from 'modules/schedule/queries/useProfessionalSchedule'
import { useUpdateSchedule } from 'modules/schedule/queries/useUpdateSchedule'
import { useModalState } from 'app/utils/useModalState'
import { FormProvider, useForm } from 'react-hook-form'
import { ScheduleTableRoot as Table } from './ScheduleTableRoot'
import EditScheduleModal from '../EditScheduleModal'
import { useAbility } from 'ability'

dayjs.extend(weekOfYear)
dayjs.extend(isTodayPlugin)

export function capitalize(str: string) {
	return str.slice(0, 1).toUpperCase().concat(str.slice(1))
}

const WEEK_RANGE = range(0, 6)
export function ScheduleTableMonth() {
	const { data, isLoading } = useProfessionalSchedule('month')

	const [isLargeScreen] = useMediaQuery('(min-width: 48em)')

	const [searchParams] = useSearchParams()
	const dateHandle = dayjs(searchParams.get('date') || Date.now())

	const firstDate = dateHandle.startOf('month')
	const lastDate = dateHandle.endOf('month')

	// const numberOfWeeks = lastDate.week() + 1 - firstDate.week()

	const lastWeekYear = lastDate.weekYear()
	const firstWeekYear = firstDate.weekYear()

	const numberOfWeeks =
		lastDate.week() +
		1 -
		firstDate.week() +
		(lastWeekYear - firstWeekYear) * 52

	return (
		<Table>
			<Table.Head>
				<tr>
					{WEEK_RANGE.map((_, i) => {
						const weekDay = dayjs(dateHandle).set('day', i)
						return (
							<th
								style={{
									padding: 8,
									textAlign: 'center',
									fontSize: 12,
								}}
								key={`th-${i}`}
							>
								{capitalize(
									weekDay
										.format(isLargeScreen ? 'dddd' : 'ddd')
										.split('-')[0]
								)}
							</th>
						)
					})}
				</tr>
			</Table.Head>
			{data && (
				<tbody>
					{Array.from({ length: numberOfWeeks }, (u, weekIndex) => {
						function getDatesInWeek() {
							return Array.from({ length: 7 }, (v, dayIndex) => {
								const initialWeek = dateHandle
									.startOf('month')
									.week()
								return dateHandle
									.startOf('weeks')
									.week(initialWeek + weekIndex)
									.add(dayIndex, 'day')
							})
						}

						return (
							<tr key={weekIndex}>
								{getDatesInWeek().map((date, i) => {
									const events = data.events.filter(
										(scheduleEvent) =>
											dayjs(scheduleEvent.start)
												.tz('America/Sao_Paulo')
												.isSame(date, 'date')
									)
									return (
										<MonthCell
											key={String(i)}
											date={date}
											selectedDate={dateHandle}
											events={events}
										/>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			)}

			{isLoading && !data && <div>Carregando...</div>}
		</Table>
	)
}

function MonthCell({
	selectedDate,
	date,
	events,
}: {
	selectedDate: dayjs.Dayjs
	date: dayjs.Dayjs
	events: ScheduleEvent[]
}) {
	const isToday = date.isToday()
	const isOutsideMonth = date.month() !== selectedDate.month()

	function getTextProps() {
		if (isToday)
			return {
				bg: 'primary.500',
				color: 'white',
			}
		return {}
	}

	const [isLargeScreen] = useMediaQuery('(min-width: 48em)')

	const shortenedEventsList = isLargeScreen ? events.slice(0, 2) : []
	const hasMore = shortenedEventsList.length !== events.length

	const renderEventsList = (eventsList: typeof events) => (
		<EventsList events={eventsList} />
	)

	const params = useParams()

	return (
		<td
			style={{
				height: 128,
				border: '1px solid lightgray',
				// opacity: isOutsideMonth ? 0.25 : 1,
			}}
		>
			<Stack as="span" py={2} px={2} h="full" alignItems="center">
				<Flex
					width="32px"
					height="32px"
					alignItems="center"
					justifyContent="center"
					mb={1}
					as={Link}
					to={`/agendamentos/${
						params.professionalId
					}/day?date=${date.toISOString()}`}
					opacity={isOutsideMonth ? 0.25 : 1}
				>
					<Text
						as="span"
						fontWeight="bold"
						w="32px"
						h="32px"
						display="flex"
						justifyContent="center"
						alignItems="center"
						borderRadius="full"
						{...getTextProps()}
					>
						{date.date()}
					</Text>
				</Flex>
				<div style={{ opacity: isOutsideMonth ? 0.25 : 1 }}>
					{renderEventsList(shortenedEventsList)}
				</div>

				{hasMore && (
					<Popover placement="top">
						<PopoverTrigger>
							<Text
								textDecoration="underline"
								cursor="pointer"
								fontSize={14}
								textAlign="center"
								opacity={isOutsideMonth ? 0.25 : 1}
							>
								Ver mais...
							</Text>
						</PopoverTrigger>
						<PopoverContent
							width="160px"
							px={3}
							py={3}
							zIndex={9999}
						>
							{renderEventsList(events)}
						</PopoverContent>
					</Popover>
				)}
			</Stack>
		</td>
	)
}

function EventsList({ events }: { events: ScheduleEvent[] }) {
	return (
		<Stack
			role="list"
			as="ul"
			display="grid"
			w="full"
			gap={1}
			style={{ marginTop: 0 }}
		>
			{events.map((event) => (
				<EventListItem key={event.id} event={event} />
			))}
		</Stack>
	)
}

function EventListItem({ event }: { event: ScheduleEvent }) {
	const scheduleModal = useModalState()
	const updateSchedule = useUpdateSchedule()

	const statusBgMap: Record<string, string> = {
		waiting: useColorModeValue('yellow.100', 'yellow.400'),
		cancelled: useColorModeValue('red.100', 'red.400'),
		finished: useColorModeValue('green.100', 'green.400'),
		scheduled: useColorModeValue('blue.100', 'blue.400'),
		confirmed: useColorModeValue('blue.400', 'blue.700'),
	}

	const getCellBg = () => {
		if (!event.status) return '#f0f0f0'
		return statusBgMap[event.status] ?? undefined
	}

	const canUpdateSchedule = useAbility().can('update', 'Appointment')

	return (
		<Grid
			as="li"
			gridTemplateColumns="auto 1fr"
			gap={1}
			style={{ marginTop: 0 }}
			onClick={
				event.customerInfo && canUpdateSchedule
					? scheduleModal.open
					: undefined
			}
		>
			<Text as="span" fontSize={12}>
				{dayjs(event.start).format('HH:mm')}
			</Text>
			<Text
				as="span"
				fontSize={10}
				borderRadius="5px"
				flex={1}
				bg={getCellBg()}
				px={2}
				pt="2px"
				textTransform="uppercase"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
				overflow="hidden"
			>
				{event.customerInfo?.name ?? '(Excluído)'}
			</Text>

			<ScheduleFormProvider
				defaultValues={{
					...event,
					start: dayjs(event.start).format('YYYY-MM-DD HH:mm'),
					end: dayjs(event.end).format('YYYY-MM-DD HH:mm'),
					customerId: event?.customerInfo?._id,
					cellphoneNumber: event?.customerInfo?.cellphoneNumber,
					title: event?.customerInfo?.name,
					// customerId: event?.customer.id,
					// id: event?.id,
					// status: event?.status,
					// notes: event?.notes,
				}}
			>
				<EditScheduleModal
					isOpen={scheduleModal.isOpen}
					onClose={scheduleModal.close}
					onSubmit={(values) => {
						updateSchedule.mutate(values, {
							onSuccess() {
								scheduleModal.close()
							},
						})
					}}
					event={event}
				/>
			</ScheduleFormProvider>
		</Grid>
	)
}

function ScheduleFormProvider({
	children,
	defaultValues,
}: {
	children: React.ReactNode
	defaultValues: Record<string, unknown>
}) {
	const form = useForm({
		defaultValues,
		// resolver: yupResolver(scheduleFormSchema),
	})

	return <FormProvider {...form}>{children}</FormProvider>
}
