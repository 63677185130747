/* eslint-disable no-underscore-dangle */
import React from 'react'
import dayjs from 'dayjs'
import { CalendarDateTime, getLocalTimeZone } from '@internationalized/date'
import { Box, Flex, Stack, Text, Textarea } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import { InputText } from 'app/components/Input'
import { InputTextController } from 'app/components/InputTextController'
import { SelectController } from 'app/components/SelectController'
import { phoneMask } from 'app/utils/masks'
import { ScheduleForm } from 'modules/schedule/validations/scheduleFormSchema'
import type { Schedule } from 'modules/schedule/queries/useProfessionalSchedule'
import { DateTimePicker } from '../DateTimePicker'
import { useSettingsByProfessional } from 'modules/schedule/queries/useSettingsByProfessional'
import { useSettingsDefault } from 'modules/schedule/queries/useSettingsDefault'
import { getValidDate } from 'app/utils/getValidDate'

const EditScheduleBodyModal = ({
	event,
}: {
	event: Schedule['events'][number]
}) => {
	const {
		control,
		formState: { errors },
		setValue,
		watch,
		getValues,
	} = useFormContext<ScheduleForm>()

	const params = useParams()
	const [searchParams] = useSearchParams()

	const date = getValidDate(searchParams.get('date'))

	const settingsByProfessional = useSettingsByProfessional(
		params.professionalId
	)
	const settingsDefault = useSettingsDefault()
	const settings = settingsByProfessional.data ?? settingsDefault.data

	const defaultDateTimeRangeRef = React.useRef<
		{ start: CalendarDateTime; end: CalendarDateTime } | undefined
	>()

	if (!settings) return null

	if (settings && !defaultDateTimeRangeRef.current) {
		const defaultValues = getValues()
		if (defaultValues.start && defaultValues.end) {
			const start = new Date(defaultValues.start)
			const end = new Date(defaultValues.end)
			defaultDateTimeRangeRef.current = {
				start: new CalendarDateTime(
					start.getFullYear(),
					start.getMonth() + 1,
					start.getDate(),
					start.getHours(),
					start.getMinutes()
				),
				end: new CalendarDateTime(
					end.getFullYear(),
					end.getMonth() + 1,
					end.getDate(),
					end.getHours(),
					end.getMinutes()
				),
			}
		} else {
			defaultDateTimeRangeRef.current = {
				start: new CalendarDateTime(
					date.getFullYear(),
					date.getMonth() + 1,
					date.getDate()
				),
				end: new CalendarDateTime(
					date.getFullYear(),
					date.getMonth() + 1,
					date.getDate()
				),
			}
		}
	}

	const customer = event?.customerInfo ?? event?.customer

	return (
		<Stack spacing={5} direction="column">
			<DateTimePicker
				defaultValue={defaultDateTimeRangeRef.current}
				settings={settings}
				onChange={(value) => {
					const start = dayjs(
						value.start.toDate(getLocalTimeZone())
					).format('YYYY-MM-DDTHH:mm')
					const end = dayjs(
						value.end.toDate(getLocalTimeZone())
					).format('YYYY-MM-DDTHH:mm')
					setValue('start', start)
					setValue('end', end)
				}}
			/>
			<Box>
				<InputText
					title="Paciente"
					placeholder=""
					type="text"
					width="100%"
					name="title"
					value={customer?.name}
					isReadOnly
				/>
				<InputTextController
					title=""
					placeholder=""
					type="hidden"
					width="100%"
					name="customerId"
					control={control}
				/>
				<Flex justifyContent="space-between" marginTop="2">
					<Link
						to={`/historico/${customer?._id}?dentist=${params.professionalId}`}
					>
						<Text
							textDecoration="underline"
							textColor="#3182CE"
							fontSize="12px"
						>
							ver histórico
						</Text>
					</Link>
					<Link to={`/tratamentos?customer=${customer?._id}`}>
						<Text
							textDecoration="underline"
							textColor="#3182CE"
							fontSize="12px"
						>
							ver tratamentos
						</Text>
					</Link>
					<Link to={`/registros/customers/${customer?._id}`}>
						<Text
							textDecoration="underline"
							textColor="#3182CE"
							fontSize="12px"
						>
							ver cadastro
						</Text>
					</Link>
				</Flex>
			</Box>
			<InputTextController
				title="Telefone"
				placeholder=""
				type="text"
				onChange={phoneMask.onChange}
				width="100%"
				name="cellphoneNumber"
				control={control}
				hasError={!!errors.cellphoneNumber?.message}
				errorMessage={errors.cellphoneNumber?.message}
				isReadOnly
				value={phoneMask.mask(event?.phone ?? '')}
			/>
			<SelectController
				items={[
					{ value: 'scheduled', label: 'Agendado' },
					{ value: 'waiting', label: 'Aguardando' },
					{ value: 'confirmed', label: 'Confirmado' },
					{ value: 'finished', label: 'Atendido' },
					{ value: 'cancelled', label: 'Cancelado' },
				]}
				title="Status *"
				width="100%"
				name="status"
				control={control}
				hasError={!!errors?.status?.message}
				errorMessage={errors?.status?.message}
				placeholder="Selecione um status"
				isRequired
			/>

			<Textarea
				placeholder="Observações"
				mb="24px"
				resize="none"
				value={watch('notes')}
				onChange={(e) => setValue('notes', e.target.value)}
			/>
		</Stack>
	)
}

export default EditScheduleBodyModal
