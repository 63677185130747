import {
	Center,
	Flex,
	Grid,
	Heading,
	IconButton,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
	Spinner,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	useDisclosure,
} from '@chakra-ui/react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import React from 'react'

import { useScheduleQuery } from '../queries/useScheduleQuery'
import { EyeIcon } from 'app/icons/EyeIcon'
import EditScheduleModal from './EditScheduleModal'
import { Slot } from '@radix-ui/react-slot'
import { yupResolver } from '@hookform/resolvers/yup'
import { scheduleFormSchema } from '../validations/scheduleFormSchema'
import { FormProvider, useForm } from 'react-hook-form'
import { useUpdateSchedule } from '../queries/useUpdateSchedule'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { TablePagination } from 'app/components/TablePagination'
import { useInfiniteCustomersQuery } from '../queries/useInfiniteCustomersQuery'
import { Item, useComboBoxState } from 'react-stately'
import { AriaComboBoxProps, useComboBox, useFilter } from 'react-aria'
import { Popover } from 'app/components/Popover'
import { ListBox } from 'app/components/ListBox'
import { Can } from 'ability'

export function ScheduleSearchRoute() {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => {
				init.set('page', String(nextPage))
				return init
			},
			{ replace: true }
		)
	}

	const start = searchParams.get('startDate') ?? ''
	const end = searchParams.get('endDate') ?? ''
	const search = searchParams.get('search') ?? ''
	const dentist = searchParams.get('dentist') ?? ''

	const query = useScheduleQuery({
		dentist,
		start,
		end,
		search,
		page,
	})

	const events = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = events?.length <= 0

	const timeoutRef = React.useRef(0)

	const renderStatus = (status?: string) => {
		if (!status) return '-'
		switch (status) {
			case 'scheduled':
				return 'Agendado'
			case 'confirmed':
				return 'Confirmado'
			case 'waiting':
				return 'Aguardando'
			case 'finished':
				return 'Atendido'
			case 'cancelled':
				return 'Cancelado'
			default:
				return status
		}
	}

	return (
		<Stack px="6" py="10" h="100%" gap="6">
			<Flex justify="space-between" alignItems="center">
				<Heading
					fontSize="2xl"
					display="inline-flex"
					alignItems="center"
					gap="4"
				>
					<button type="button" onClick={() => navigate(-1)}>
						<ChevronLeftIcon fontSize={28} />
					</button>
					Buscar Agendamentos
				</Heading>
			</Flex>

			<Grid
				gap="6"
				templateColumns={{ base: 'auto', md: '572fr 274fr 274fr' }}
				templateRows={{ base: 'repeat(3,1fr)', md: 'auto' }}
				as="form"
				onChange={(e) => {
					const form: unknown = e.currentTarget
					const formData = new FormData(form as HTMLFormElement)

					clearTimeout(timeoutRef.current)
					timeoutRef.current = window.setTimeout(() => {
						const entries = Object.entries({
							...Object.fromEntries(searchParams),
							...Object.fromEntries(formData),
							page: 1,
						})
							.filter(([, v]) => Boolean(v))
							.map(([k, v]) => [k, String(v)])
						setSearchParams(new URLSearchParams(entries), {
							replace: true,
						})
					}, 400)
				}}
				onSubmit={(e) => e.preventDefault()}
			>
				<CustomerInputGroup />

				<InputGroup
					size="sm"
					display="flex"
					alignItems="center"
					gap="3"
				>
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Data Início
					</Text>
					<Input
						size="sm"
						name="startDate"
						defaultValue={searchParams.get('startDate')}
						bg="white"
						_dark={{ bg: '#182533' }}
						type="date"
					/>
				</InputGroup>

				<InputGroup
					size="sm"
					display="flex"
					alignItems="center"
					gap="3"
				>
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Data Fim
					</Text>
					<Input
						size="sm"
						name="endDate"
						defaultValue={searchParams.get('endDate')}
						bg="white"
						_dark={{ bg: '#182533' }}
						type="date"
					/>
				</InputGroup>
			</Grid>

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							<Th>Paciente</Th>
							<Th>Profissional</Th>
							<Th>Observação</Th>
							<Th>Data Início</Th>
							<Th>Data Fim</Th>
							<Th textAlign="center">Situação</Th>
						</tr>
					</Thead>
					<Tbody>
						{events &&
							!isEmpty &&
							events.map((it) => (
								<tr key={`event:${it._id}`}>
									<Td>
										<Text>{it.customer?.name}</Text>
									</Td>
									<Td>
										<Text>{it.dentist?.name}</Text>
									</Td>
									<Td>
										<div
											style={{ display: 'grid' }}
											title={it.notes}
										>
											<Text
												whiteSpace="nowrap"
												textOverflow="ellipsis"
												overflow="hidden"
											>
												{it.notes}
											</Text>
										</div>
									</Td>
									<Td>
										{it.start
											? dayjs(it.start).format(
													'DD/MM/YYYY HH:mm'
											  )
											: '-'}
									</Td>
									<Td>
										{it.end
											? dayjs(it.end).format(
													'DD/MM/YYYY HH:mm'
											  )
											: '-'}
									</Td>
									<Td textAlign="center">
										{renderStatus(it.status)}
									</Td>
									<Td textAlign="center">
										<Can I="update" a="Appointment">
											<ScheduleModal
												trigger={
													<IconButton
														bg="#f9f9f9"
														_dark={{
															bg: '#223344',
														}}
														aria-label="Ver agendamento"
														icon={
															<EyeIcon
																size={20}
															/>
														}
													/>
												}
												event={it}
											/>
										</Can>
									</Td>
								</tr>
							))}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Nenhum resultado para a sua busca
										</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>

			{paginationInfo && paginationInfo.total > 0 && (
				<Flex alignItems="center" justify="end">
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						ml="5"
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</Stack>
	)
}

function CustomerInputGroup() {
	const [searchParams, setSearchParams] = useSearchParams()
	const [customerSearch, setCustomerSearch] = React.useState(
		searchParams.get('search') ?? ''
	)

	const customersQuery = useInfiniteCustomersQuery({ name: customerSearch })
	const customers = customersQuery.data ?? []

	return (
		<InputGroup flex={1} size="sm" maxW="543px">
			<Combobox
				name="search"
				placeholder="Pesquise por paciente"
				items={customers}
				loading={
					(!customersQuery.data ||
						customersQuery.isPlaceholderData) &&
					customersQuery.isFetching
				}
				inputValue={customerSearch}
				onInputChange={setCustomerSearch}
				onSelectionChange={(key) => {
					const selected = customers.find((it) => it.id === key)
					if (selected) {
						setSearchParams(
							(sp) => {
								sp.set('search', selected.name)
								return sp
							},
							{ replace: true }
						)
					}
				}}
				loadingMore={customersQuery.isFetchingNextPage}
				onLoadMore={React.useCallback(() => {
					if (
						customersQuery.hasNextPage &&
						!customersQuery.isFetching
					)
						customersQuery.fetchNextPage()
				}, [customersQuery.hasNextPage, customersQuery.isFetching])}
			>
				{(item) => (
					<ComboboxItem key={item.id}>{item.name}</ComboboxItem>
				)}
			</Combobox>
		</InputGroup>
	)
}

type Props<T> = AriaComboBoxProps<T> &
	Omit<InputProps, 'children'> & {
		name?: string

		loading?: boolean
		loadingMore?: boolean
		onLoadMore?: () => void
	}

function Combobox<T extends object>({
	items,
	children,
	inputValue,
	onInputChange,

	loading,
	loadingMore,
	onLoadMore,
	...props
}: Props<T>) {
	const filter = useFilter({ sensitivity: 'base' })
	const state = useComboBoxState({
		...props,
		items,
		children,
		inputValue,
		onInputChange,
		defaultFilter: filter.contains,
	})
	const inputRef = React.useRef<HTMLInputElement>(null)
	const listBoxRef = React.useRef(null)
	const popoverRef = React.useRef(null)
	const combobox = useComboBox(
		{ ...props, items, inputRef, listBoxRef, popoverRef },
		state
	)

	React.useEffect(() => {
		if (!loading && inputRef.current === document.activeElement) {
			state.open()
		}
	}, [loading])

	return (
		<InputGroup position="relative" size="md">
			<Input
				{...combobox.inputProps}
				{...props}
				ref={inputRef}
				size="md"
				onFocus={() => state.open()}
			/>
			{loading && (
				<InputRightElement>
					<Spinner color="primary.800" size="sm" />
				</InputRightElement>
			)}

			{state.isOpen && (
				<Popover
					popoverRef={popoverRef}
					triggerRef={inputRef}
					state={state}
					isNonModal
					placement="bottom start"
				>
					<ListBox
						{...combobox.listBoxProps}
						listBoxRef={listBoxRef}
						state={state}
						loadingMore={loadingMore}
						onLoadMore={onLoadMore}
					/>
				</Popover>
			)}
		</InputGroup>
	)
}

const ComboboxItem = Item

interface ScheduleModalProps {
	trigger: JSX.Element
	event: any
}

function ScheduleModal({ trigger, event }: ScheduleModalProps) {
	const state = useDisclosure()
	const form = useForm({
		defaultValues: {
			start: event?.start
				? dayjs(event.start).format('YYYY-MM-DDTHH:mm:ss')
				: undefined,
			end: event?.end
				? dayjs(event.end).format('YYYY-MM-DDTHH:mm:ss')
				: undefined,
			customerId: event?.customer?._id,
			id: event?._id,
			cellphoneNumber: event?.customer?.cellphoneNumber,
			status: event?.status,
			notes: event?.notes,
			title: event?.customer?.cellphoneNumber,
		},
		resolver: yupResolver(scheduleFormSchema),
	})

	const updateSchedule = useUpdateSchedule()

	return (
		<FormProvider {...form}>
			<Slot onClick={state.onOpen}>{trigger}</Slot>
			<EditScheduleModal
				isOpen={state.isOpen}
				onClose={state.onClose}
				event={event}
				onSubmit={(values) => {
					updateSchedule.mutate(values, {
						onSuccess() {
							state.onClose()
						},
					})
				}}
			/>
		</FormProvider>
	)
}
