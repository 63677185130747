import * as yup from 'yup'

const defaultConfirmationMessageTemplate =
	`Oi, {{ cliente }}.\n` +
	`Sua consulta foi marcada para {{ dia_da_semana }} {{ data }}, às {{ hora }}.\n` +
	`Dentista: {{ dentista }}`

export const settingsSchema = yup.object({
	agenda: yup.object({
		sessionDurationMinutes: yup.number().positive().required(),
		startTime: yup.string().required(),
		endTime: yup.string().required(),
		lunchStartTime: yup.string().optional(),
		lunchEndTime: yup.string().optional(),
		availableDays: yup.array(yup.string()).required(),
	}),
	annotations: yup.object({
		sessionDurationMinutes: yup.number().positive().required(),
		startTime: yup.string().required(),
		endTime: yup.string().required(),
	}),
	confirmationMessage: yup
		.string()
		.default(defaultConfirmationMessageTemplate),
})

export type Settings = yup.InferType<typeof settingsSchema>
